import "./style.scss";
import trash from "../../assets/icons/Trash.svg";
import downloadFile from "../../assets/icons/FileArrowDown.svg";
import { useState } from "react";
import DeleteModal from "../DeleteModal";
import { formatDate } from "../../constants";
import { deleteTracks, getTracksById } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface props {
  data: any;
}

const SubmittedTrack: React.FC<props> = ({ data }) => {
  const token = sessionStorage.getItem("token");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tracks, setTracks] = useState(data);
  const [trackId, setTrackId] = useState<any>();

  const handleDelete = () => {
    deleteTracks(trackId, token)
      .then((res) => {
        setShowModal(false);
        const updatedArray = data.filter(
          (object: { _id: any }) => object._id !== trackId
        );
        setTracks(updatedArray);
        toast.success("Track deleted successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleDownload = (_id: string, index: number) => {
    getTracksById(_id, token).then((res: any) => {
      console.log(res);
      const track = res.list;
      downloadTrack(track.file);
    });
  };

  const downloadTrack = (url: any) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <ToastContainer />
      <div className="SubmittedTrackContainer">
        <div className="wrapper">
          <div className="table-container">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Track Name</th>
                  <th>Album Name</th>
                  <th className="!text-center">Genre</th>
                  <th className="!text-center">Upload Date</th>
                  <th className="flex justify-center">Download & Delete</th>
                </tr>
              </thead>
              <tbody>
                {tracks &&
                  tracks.map((track: any, index: number) => (
                    <tr key={index}>
                      <td>{track.trackName}</td>
                      <td>{track.albumName}</td>
                      <td className="!text-center">{track.selectGenre}</td>
                      <td className="!text-center">
                        {formatDate(track.updatedAt)}
                      </td>
                      <td className="flex flex-row gap-5 justify-center cursor-pointer">
                        <img
                          className="w-7"
                          src={downloadFile}
                          alt="downloadFile"
                          onClick={() => {
                            handleDownload(track['_id'], index);
                          }}
                        />
                        <img
                          className="w-7"
                          src={trash}
                          alt="trash"
                          onClick={() => {
                            setTrackId(track._id);
                            setShowModal(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!tracks.length && (
              <div className="h-[200px] flex justify-center items-center">
                <h1 className="text-2xl font-bold">No data found</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <DeleteModal onClose={() => setShowModal(false)} onYes={handleDelete} message={"Hello there, are you sure you want to remove this track from the list?"} />
      )}
    </>
  );
};

export default SubmittedTrack;
