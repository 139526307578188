import "./style.scss";
import trash from "../../assets/icons/Trash.svg";
import downloadFile from "../../assets/icons/FileArrowDown.svg";
import filter from "../../assets/icons/filter.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FilterModal from "../../components/FilterModal";
import { deleteTracks, getAllTracks, getTracksById } from "../../utils/auth";
import { formatDate, formatDate1 } from "../../constants";
import { Pagination } from "@mui/material";
import arrowDown from "../../assets/icons/arrow_down.svg";
import DeleteModal from "../../components/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader";

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [tracks, setTracks] = useState([]);
  const limit = 10;
  const [page, setPage] = useState<number>(1);
  const [skip, setSkip] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const [appliedFilters, setAppliedFilters] = useState<any>({
    genres: [],
    locations: [],
    language: [],
    dates: [],
  });
  const [trackId, setTrackId] = useState<any>();
  const [sortField, setSortField] = useState("");
  const [ascDesc, setAscDesc] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const data = {
      limit: limit,
      skip: skip,
      sortField: sortField,
      sortOrder: ascDesc,
      ...(appliedFilters.genres.length > 0 && {
        selectGenre: appliedFilters.genres,
      }),
      ...(appliedFilters.locations.length > 0 && {
        countryResidence: appliedFilters.locations,
      }),
      ...(appliedFilters.language.length > 0 && {
        languageForLyrics: appliedFilters.language,
      }),
      startDate: appliedFilters.dates.startDate
        ? formatDate1(appliedFilters.dates.startDate)
        : "",
      endDate: appliedFilters.dates.endDate
        ? formatDate1(appliedFilters.dates.endDate)
        : "",
      search: searchValue,
    };
    getAllTracks(data, token).then((res) => {
      setTracks(res.list);
      setTotalPages(Math.ceil(res.count / 10));
      setIsLoading(false);
    });
  }, [appliedFilters, page, limit, skip, token, sortField, ascDesc, searchValue]);

  const handleApplyFilters = (filters: any) => {
    setAppliedFilters(filters);
    setShowModal(false);
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
    setSkip((value - 1) * 10);
  };

  const handleDownload = (_id: string) => {
    getTracksById(_id, token).then((res: any) => {
      const track = res.list;
      downloadTrack(track.file);
    });
  };

  const downloadTrack = (url: any) => {
    window.open(url, "_blank");
  };

  const handleDelete = () => {
    deleteTracks(trackId, token)
      .then((res) => {
        setIsDelete(false);
        const updatedArray = tracks.filter(
          (object: { _id: any }) => object._id !== trackId
        );
        setTracks(updatedArray);
        toast.success("Track deleted successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleSort = (sortField: string) => {
    setSortField(sortField);
    ascDesc === "asc" ? setAscDesc("desc") : setAscDesc("asc");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) clearTimeout(timer);
    const value = e.target.value;
    setTimer(
      setTimeout(() => {
        setSearchValue(value)
      }, 500)
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="dashboardContainer">
        <div className="title">
          <div className="heading">
            <h1>Admin Music Library</h1>
            <h3>Here are the overall tracks that User’s uploaded.</h3>
          </div>
        </div>

        <div className="wrapper">
          <div className="flex justify-end gap-10">
            {/* <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
            /> */}
            <div className="filter">
              <button
                className="filter-btn"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <img src={filter} alt="filter" /> Filter
              </button>
            </div>
          </div>
          <div className="table-container">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>
                    User Profile{" "}
                    <img
                      width={10}
                      src={arrowDown}
                      alt="arrowDown"
                      onClick={() => {
                        handleSort("firstName");
                      }}
                    />
                  </th>
                  <th>
                    Track Name{" "}
                    <img
                      width={10}
                      src={arrowDown}
                      alt="arrowDown"
                      onClick={() => {
                        handleSort("trackName");
                      }}
                    />
                  </th>
                  <th>Genre</th>
                  <th>Album Name</th>
                  <th>Language</th>
                  <th>Location</th>
                  <th className="!text-center">
                    Upload Date{" "}
                    <img
                      width={10}
                      src={arrowDown}
                      alt="arrowDown"
                      onClick={() => {
                        handleSort("createdAt");
                      }}
                    />
                  </th>
                  <th className="flex justify-center">Download & Delete</th>
                </tr>
              </thead>
              <tbody>
                {tracks &&
                  tracks.map((track: any, index) => (
                    <tr key={index}>
                      <td
                        className="underline"
                        style={{
                          color: "#4285F4",
                          fontWeight: "800",
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          navigate("/user-profile/" + track.userId);
                        }}
                      >
                        {track.firstName}
                      </td>
                      <td>{track.trackName}</td>
                      <td>{track.selectGenre}</td>
                      <td>{track.albumName}</td>
                      <td>{track.languageForLyrics}</td>
                      <td>{track.countryResidence}</td>
                      <td className="!text-center">
                        {formatDate(track.createdAt)}
                      </td>
                      <td>
                        <div className="flex flex-row gap-5 justify-center cursor-pointer">
                          <img
                            className="w-7"
                            src={downloadFile}
                            alt="downloadFile"
                            onClick={() => {
                              handleDownload(track['_id']);
                            }}
                          />
                          <img
                            className="w-7"
                            src={trash}
                            alt="trash"
                            onClick={() => {
                              setTrackId(track._id);
                              setIsDelete(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!tracks.length && (
              <div className="h-[200px] flex justify-center items-center">
                <h1 className="text-2xl font-bold">No data found</h1>
              </div>
            )}
          </div>
          {tracks.length ? (
            <div className="pagination">
              <Pagination
                count={totalPages}
                shape="rounded"
                page={page}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showModal && (
        <FilterModal
          onClose={() => setShowModal(false)}
          onApply={handleApplyFilters}
          appliedFilters={appliedFilters}
        />
      )}
      {isDelete && (
        <DeleteModal
          onClose={() => setIsDelete(false)}
          onYes={handleDelete}
          message={
            "Hello there, are you sure you want to remove this track from the list?"
          }
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default AdminDashboard;
